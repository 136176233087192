import Aux from "./Aux"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        type: ['alert', 'message'],
        ...filter
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return {
        audience: _.get(values, 'is_paired') || _.get(values, 'audience'),
        drivers: _.get(values, 'drivers'),
        driver_options: _.get(values, 'driver_options'),
        vehicle_options: _.get(values, 'vehicle_options'),
        type: _.get(values, 'type'),
        title: !!_.get(values, 'title') ? { 'contains': values.title } : undefined,
        body: !!_.get(values, 'body') ? { 'contains': values.body } : undefined,
        createdAt: Aux.formatCreatedAt(_.get(values, 'createdAt_start'), _.get(values, 'createdAt_end'))
      }
    }
  }
}

export default Callbacks
