class Callbacks {
  static onSubmitSuccessHandler() {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler() {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err)
    }
  }
  static onSubmitHandler(searchIncidents) {
    return async function onSubmit(values) {
      return await searchIncidents({ active: 1, ...values })
    }
  }
}

export default Callbacks
