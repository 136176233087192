import React, { useMemo } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableCell, Button, Box } from '@mui/material'
import { Loader } from '@front/squirtle'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  const onClick = hooks.useFileDownload('GET', 'task/download', { task_id: _.get(row, 'task_id') })
  const [{ name: operator_name }] = hooks.useCustomerInfo(row?.operator?.user_id)

  const orderState = useMemo(() => {
    switch (_.get(row, 'status')) {
      case 'failed':
        return I18n.t('error.label.one')
      default:
        return I18n.t('wait.in')
    }
  }, [_.get(row, 'status')])

  return <>
    <TableCell><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'createdAt'))} /></TableCell>
    <TableCell><TextCell value={I18n.t(`export.name.${_.get(row, 'name')}`)} /></TableCell>
    <TableCell><TextCell value={operator_name} /></TableCell>
    <TableCell sx={{ textAlign: 'center' }}>
      <Loader isLoading={_.get(row, 'status') === 'running'}>
        {
          _.get(row, 'status') === 'complete' ?
            <Button color='primary' size='small' onClick={() => onClick()} variant='contained'>
              {I18n.t('action.download')}
            </Button> :
            orderState
        }
      </Loader>
      {_.get(row, 'status') === 'running' && <Box>{`${_.get(row, 'progress', 0)} %`}</Box>}
    </TableCell>
  </>
}

export default React.memo(Row)
